import endpoint from "@/api/endpoint";

export default {

    all(data) {
        return window.axios.get(endpoint + '/api/project/' + data.id + '/costs', data);
    },

    create(data) {
        return window.axios.post(endpoint + '/api/project/' + data.project_id + '/costs', data);
    },

    update(data) {
        return window.axios.post(endpoint + '/api/project/' + data.project_id + '/costs/' + data.id, data);
    },

    delete(data) {
        return window.axios.delete(endpoint + '/api/project/' + data.project_id + '/costs/' + data.id, data);
    },


}
