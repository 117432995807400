<script>

import project_costs from "@/api/project_costs";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import CardUI from "@/components/UI/CardUI.vue";
import InputUI from "@/components/UI/InputUI.vue";
import SelectorUI from "@/components/UI/SelectorUI.vue";

export default {
  name: "ProjectItemCostsComponent",
  components: {SelectorUI, InputUI, CardUI, ButtonUI},
  computed: {
    project() {
      return this.$store.state.projects.currentProject;
    },
    total() {
      return this.costs.reduce((a, b) => a + (b.quantity * (b.type === 'cost' ? -b.amount : b.amount)), 0);
    }
  },
  methods: {
    async load() {
      this.costs = (await project_costs.all({id: this.$store.state.projects.currentProject.id})).data.map((i) => ({
        ...i,
        type: i.amount > 0 ? 'income' : 'cost',
        amount: Math.abs(i.amount)
      }));
    },
    async save() {
      this.loading = true;
      if (this.editCost.id) {
        await project_costs.update({
          ...this.editCost,
          amount: this.editCost.type === 'income' ? this.editCost.amount : -this.editCost.amount,
          project_id: this.$store.state.projects.currentProject.id
        })
      } else {
        await project_costs.create({
          ...this.editCost,
          amount: this.editCost.type === 'income' ? this.editCost.amount : -this.editCost.amount,
          project_id: this.$store.state.projects.currentProject.id
        })
      }
      await this.load();
      this.loading = false;
      this.createDialogOpen = false;

    },
    async remove(id) {
      await project_costs.delete({id, project_id: this.$store.state.projects.currentProject.id})
      await this.load();
    }
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      costs: [],
      createDialogOpen: false,
      loading: false,
      editCost: {
        id: null,
        type: 'income',
        title: '',
        description: '',
        amount: '',
        quantity: '',
        unit: '',
      },
      headers: [
        {text: 'Тип', align: 'start', sortable: false, value: 'type'},
        {text: 'Название', align: 'start', sortable: false, value: 'title'},
        {text: 'Описание', align: 'start', sortable: false, value: 'description', width: '200px'},
        {text: 'Стоимость', align: 'start', sortable: false, value: 'amount'},
        {text: 'Кол-во', align: 'start', sortable: false, value: 'quantity', width: '80px'},
        {text: 'Итого', align: 'start', sortable: false, value: 'total', width: '100px'},
        {text: 'Действия', align: 'start', sortable: false, value: 'edit', width: '100px'},
      ]
    }
  }
}
</script>

<template>
  <div>
    <v-dialog origin="bottom center" @click:outside="createDialogOpen = false" style="top: 200px !important;"
              v-model="createDialogOpen"
              max-width="600"
    >
      <CardUI :loading="false">
        <template slot="body">
          <v-card-title v-if="!editCost.id" class="headline">
            Добавить в смету
          </v-card-title>
          <v-card-title v-else class="headline">
            Изменение сметы
          </v-card-title>
          <div class="pa-4">
            <SelectorUI v-model="editCost.type" :items="[
                                         {value: 'income', name: 'Доход'},
                                         {value: 'cost', name: 'Расход'},

                                      ]" :default-value="'income'"/>
            <h4 class="my-2">Название</h4>
            <InputUI v-model="editCost.title" counter dense outlined
                     :placeholder="`${editCost.type === 'income' ? 'Продажа продукции' : 'Покупка шкафа'}`"
                     prepend-icon="mdi-text"></InputUI>
            <h4 class="my-2">Описание</h4>
            <InputUI v-model="editCost.description" counter dense outlined
                     :placeholder="`${editCost.type === 'income' ? 'Доход от продажи продукции' : 'Шкаф для хранения продукции'}`"
                     prepend-icon="mdi-text"></InputUI>
            <h4 class="my-2">Сумма (руб)</h4>
            <InputUI type="number" v-model="editCost.amount" counter dense outlined
                     :placeholder="1000" prepend-icon="mdi-text"></InputUI>

            <h4 class="my-2">Кол-во</h4>
            <InputUI type="number" v-model="editCost.quantity" counter dense outlined
                     :placeholder="1" prepend-icon="mdi-text"></InputUI>

            <h4 class="my-2">Ед. измерения</h4>
            <InputUI v-model="editCost.unit" counter dense outlined
                     placeholder="шт" prepend-icon="mdi-text"></InputUI>
            <h4 class="my-2">Итого (руб) </h4>
            <InputUI :value="editCost.quantity * editCost.amount" read-only counter dense outlined
                     prepend-icon="mdi-text"></InputUI>

            <ButtonUI :loading="loading" v-if="!editCost.id" @click="save">
              Добавить
            </ButtonUI>
            <ButtonUI v-else @click="save"
                      :loading="loading">
              Сохранить
            </ButtonUI>
          </div>
        </template>
      </CardUI>
    </v-dialog>

    <v-sheet class="pa-4 mb-2" style="border-radius: 10px;">
      <h4 class="title">Смета проекта
        <div v-if="project.permissions.can_edit_info" @click="createDialogOpen = true" style="cursor:pointer;"
             class="float-right add-task">
          <v-icon>mdi-plus</v-icon>
        </div>
      </h4>
      <v-data-table
          no-data-text="Нет элементов"
          :headers="headers"
          :items="costs"
          :footer-props="{itemsPerPageText: 'Позиций на странице'}"
          item-key="id"
      >
        <template v-slot:item.total="{ item }">
          {{ (item.type === 'cost' ? -item.amount : item.amount) * item.quantity }} ₽
        </template>


        <template v-slot:footer>
          <div class="mt-2 w-100 text-right">
            Итого: {{ total }} ₽
          </div>
        </template>

        <template v-if="project.permissions.can_edit_info" v-slot:item.edit="{ item }">
          <v-btn @click="editCost = {...item}; createDialogOpen = true" class="ml-3" x-small icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="remove(item.id)" class="ml-3" x-small icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.type="{ item }">
          <v-chip color="red" dark v-if="item.type === 'cost'" x-small>Расходы</v-chip>
          <v-chip color="green" dark v-else x-small>Доходы</v-chip>
        </template>

      </v-data-table>

    </v-sheet>
  </div>
</template>

<style>
</style>
